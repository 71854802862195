import { Subscription } from 'rxjs';
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AnalysisType } from 'src/app/navigation/services/navigation-services/parent-navigation-service';
import { LicenseService } from 'src/app/services/licensing-service';
import { MenuPositionX } from '@angular/material/menu';

export enum AnalysisMenuCaller {
  Homepage,
  Sidenav,
  Packaging,
  StartingPage
}

export class MenuItem {
  versionId?: number;
  date = '';
  time = '';
  analysisVersions!: { displayDate: string, underlyingDate: Date, analysisVersionId: string }[];
  analysisType?: number;
}

export class PackagingValidationMapping {
  id = -1;
  isFullyValidatedAllValid = false;
  isFullyValidatedNotAllValid = false;
  isNotFullyValidated = false;
  isCurrent = false;
  isUntouched = false;
}

@Component({
  selector: 'app-analyses-menu-parent',
  templateUrl: './analyses-menu-parent.component.html',
  styleUrls: ['./analyses-menu-parent.component.scss']
})
export class AnalysesMenuParentComponent implements OnDestroy {

  @Input() versionId = -1;
  @Input() hasRecyclabilityResult = false;
  @Input() hasLifeCycleResult = false;
  @Input() creationTimestamp!: Date;
  @Input() isUserValidator = false;
  @Input() caller!: AnalysisMenuCaller;

  @Output() validationData = new EventEmitter();

  recyclabilityAnalysisMenu: { menuItems: MenuItem[], analysisType: number };
  lcaAnalysisMenu: { menuItems: MenuItem[], analysisType: number };
  plasticTaxMenu: { menuItems: MenuItem[], analysisType: number };
  licenseFeeMenu: { menuItems: MenuItem[], analysisType: number };

  isAnalysisAllowed = true;
  menuDirectionX: MenuPositionX = 'before';

  protected recSubscription?: Subscription;
  protected lcaSubscription?: Subscription;
  protected taxSubscription?: Subscription;
  protected feeSubscription?: Subscription;
  protected creditsSubscription?: Subscription;
  protected permissionsSubscription?: Subscription;
  protected analysisPossibleSubscription?: Subscription;
  protected versionsSubscription?: Subscription;
  protected timestampSubscription?: Subscription;

  recMenuItemsLoaded?: Promise<boolean>;
  lcaMenuItemsLoaded?: Promise<boolean>;
  taxMenuItemsLoaded?: Promise<boolean>;
  feeMenuItemsLoaded?: Promise<boolean>;

  constructor(private licenseService: LicenseService) {
    this.recyclabilityAnalysisMenu = { menuItems: [], analysisType: AnalysisType.Recyclability };
    this.lcaAnalysisMenu = { menuItems: [], analysisType: AnalysisType.Lifecycle };
    this.plasticTaxMenu = { menuItems: [], analysisType: AnalysisType.PlasticTax };
    this.licenseFeeMenu = { menuItems: [], analysisType: AnalysisType.LicenseFee };
  }

  protected getDateTimeString(date: string) {
    const dateDate = new Date(date);
    return `${dateDate.toLocaleDateString()}: ${dateDate.toLocaleTimeString()}`;
  }

  ngOnDestroy(): void {
    this.recSubscription?.unsubscribe();
    this.lcaSubscription?.unsubscribe();
    this.taxSubscription?.unsubscribe();
    this.feeSubscription?.unsubscribe();
    this.creditsSubscription?.unsubscribe();
    this.permissionsSubscription?.unsubscribe();
    this.analysisPossibleSubscription?.unsubscribe();
    this.versionsSubscription?.unsubscribe();
    this.timestampSubscription?.unsubscribe();
  }

  isLcaAllowed(): boolean {
    return this.licenseService.isLcaAllowed;
  }

  isCCAllowed(): boolean {
    return this.licenseService.isCCAllowed;
  }
}
